// Copyright 2023 LiveKit, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.3.0 with parameter "target=ts"
// @generated from file livekit_rtc.proto (package livekit, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ClientConfiguration, ConnectionQuality, DisconnectReason, Encryption_Type, ParticipantInfo, ParticipantTracks, Room, ServerInfo, SpeakerInfo, SubscriptionError, TrackInfo, TrackSource, TrackType, VideoLayer, VideoQuality } from "./livekit_models_pb.js";

/**
 * @generated from enum livekit.SignalTarget
 */
export enum SignalTarget {
  /**
   * @generated from enum value: PUBLISHER = 0;
   */
  PUBLISHER = 0,

  /**
   * @generated from enum value: SUBSCRIBER = 1;
   */
  SUBSCRIBER = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SignalTarget)
proto3.util.setEnumType(SignalTarget, "livekit.SignalTarget", [
  { no: 0, name: "PUBLISHER" },
  { no: 1, name: "SUBSCRIBER" },
]);

/**
 * @generated from enum livekit.StreamState
 */
export enum StreamState {
  /**
   * @generated from enum value: ACTIVE = 0;
   */
  ACTIVE = 0,

  /**
   * @generated from enum value: PAUSED = 1;
   */
  PAUSED = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(StreamState)
proto3.util.setEnumType(StreamState, "livekit.StreamState", [
  { no: 0, name: "ACTIVE" },
  { no: 1, name: "PAUSED" },
]);

/**
 * @generated from enum livekit.CandidateProtocol
 */
export enum CandidateProtocol {
  /**
   * @generated from enum value: UDP = 0;
   */
  UDP = 0,

  /**
   * @generated from enum value: TCP = 1;
   */
  TCP = 1,

  /**
   * @generated from enum value: TLS = 2;
   */
  TLS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(CandidateProtocol)
proto3.util.setEnumType(CandidateProtocol, "livekit.CandidateProtocol", [
  { no: 0, name: "UDP" },
  { no: 1, name: "TCP" },
  { no: 2, name: "TLS" },
]);

/**
 * @generated from message livekit.SignalRequest
 */
export class SignalRequest extends Message<SignalRequest> {
  /**
   * @generated from oneof livekit.SignalRequest.message
   */
  message: {
    /**
     * initial join exchange, for publisher
     *
     * @generated from field: livekit.SessionDescription offer = 1;
     */
    value: SessionDescription;
    case: "offer";
  } | {
    /**
     * participant answering publisher offer
     *
     * @generated from field: livekit.SessionDescription answer = 2;
     */
    value: SessionDescription;
    case: "answer";
  } | {
    /**
     * @generated from field: livekit.TrickleRequest trickle = 3;
     */
    value: TrickleRequest;
    case: "trickle";
  } | {
    /**
     * @generated from field: livekit.AddTrackRequest add_track = 4;
     */
    value: AddTrackRequest;
    case: "addTrack";
  } | {
    /**
     * mute the participant's published tracks
     *
     * @generated from field: livekit.MuteTrackRequest mute = 5;
     */
    value: MuteTrackRequest;
    case: "mute";
  } | {
    /**
     * Subscribe or unsubscribe from tracks
     *
     * @generated from field: livekit.UpdateSubscription subscription = 6;
     */
    value: UpdateSubscription;
    case: "subscription";
  } | {
    /**
     * Update settings of subscribed tracks
     *
     * @generated from field: livekit.UpdateTrackSettings track_setting = 7;
     */
    value: UpdateTrackSettings;
    case: "trackSetting";
  } | {
    /**
     * Immediately terminate session
     *
     * @generated from field: livekit.LeaveRequest leave = 8;
     */
    value: LeaveRequest;
    case: "leave";
  } | {
    /**
     * Update published video layers
     *
     * @generated from field: livekit.UpdateVideoLayers update_layers = 10;
     */
    value: UpdateVideoLayers;
    case: "updateLayers";
  } | {
    /**
     * Update subscriber permissions
     *
     * @generated from field: livekit.SubscriptionPermission subscription_permission = 11;
     */
    value: SubscriptionPermission;
    case: "subscriptionPermission";
  } | {
    /**
     * sync client's subscribe state to server during reconnect
     *
     * @generated from field: livekit.SyncState sync_state = 12;
     */
    value: SyncState;
    case: "syncState";
  } | {
    /**
     * Simulate conditions, for client validations
     *
     * @generated from field: livekit.SimulateScenario simulate = 13;
     */
    value: SimulateScenario;
    case: "simulate";
  } | {
    /**
     * client triggered ping to server
     *
     * deprecated by ping_req (message Ping)
     *
     * @generated from field: int64 ping = 14;
     */
    value: bigint;
    case: "ping";
  } | {
    /**
     * update a participant's own metadata and/or name
     *
     * @generated from field: livekit.UpdateParticipantMetadata update_metadata = 15;
     */
    value: UpdateParticipantMetadata;
    case: "updateMetadata";
  } | {
    /**
     * @generated from field: livekit.Ping ping_req = 16;
     */
    value: Ping;
    case: "pingReq";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SignalRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SignalRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "offer", kind: "message", T: SessionDescription, oneof: "message" },
    { no: 2, name: "answer", kind: "message", T: SessionDescription, oneof: "message" },
    { no: 3, name: "trickle", kind: "message", T: TrickleRequest, oneof: "message" },
    { no: 4, name: "add_track", kind: "message", T: AddTrackRequest, oneof: "message" },
    { no: 5, name: "mute", kind: "message", T: MuteTrackRequest, oneof: "message" },
    { no: 6, name: "subscription", kind: "message", T: UpdateSubscription, oneof: "message" },
    { no: 7, name: "track_setting", kind: "message", T: UpdateTrackSettings, oneof: "message" },
    { no: 8, name: "leave", kind: "message", T: LeaveRequest, oneof: "message" },
    { no: 10, name: "update_layers", kind: "message", T: UpdateVideoLayers, oneof: "message" },
    { no: 11, name: "subscription_permission", kind: "message", T: SubscriptionPermission, oneof: "message" },
    { no: 12, name: "sync_state", kind: "message", T: SyncState, oneof: "message" },
    { no: 13, name: "simulate", kind: "message", T: SimulateScenario, oneof: "message" },
    { no: 14, name: "ping", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "message" },
    { no: 15, name: "update_metadata", kind: "message", T: UpdateParticipantMetadata, oneof: "message" },
    { no: 16, name: "ping_req", kind: "message", T: Ping, oneof: "message" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignalRequest {
    return new SignalRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignalRequest {
    return new SignalRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignalRequest {
    return new SignalRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignalRequest | PlainMessage<SignalRequest> | undefined, b: SignalRequest | PlainMessage<SignalRequest> | undefined): boolean {
    return proto3.util.equals(SignalRequest, a, b);
  }
}

/**
 * @generated from message livekit.SignalResponse
 */
export class SignalResponse extends Message<SignalResponse> {
  /**
   * @generated from oneof livekit.SignalResponse.message
   */
  message: {
    /**
     * sent when join is accepted
     *
     * @generated from field: livekit.JoinResponse join = 1;
     */
    value: JoinResponse;
    case: "join";
  } | {
    /**
     * sent when server answers publisher
     *
     * @generated from field: livekit.SessionDescription answer = 2;
     */
    value: SessionDescription;
    case: "answer";
  } | {
    /**
     * sent when server is sending subscriber an offer
     *
     * @generated from field: livekit.SessionDescription offer = 3;
     */
    value: SessionDescription;
    case: "offer";
  } | {
    /**
     * sent when an ICE candidate is available
     *
     * @generated from field: livekit.TrickleRequest trickle = 4;
     */
    value: TrickleRequest;
    case: "trickle";
  } | {
    /**
     * sent when participants in the room has changed
     *
     * @generated from field: livekit.ParticipantUpdate update = 5;
     */
    value: ParticipantUpdate;
    case: "update";
  } | {
    /**
     * sent to the participant when their track has been published
     *
     * @generated from field: livekit.TrackPublishedResponse track_published = 6;
     */
    value: TrackPublishedResponse;
    case: "trackPublished";
  } | {
    /**
     * Immediately terminate session
     *
     * @generated from field: livekit.LeaveRequest leave = 8;
     */
    value: LeaveRequest;
    case: "leave";
  } | {
    /**
     * server initiated mute
     *
     * @generated from field: livekit.MuteTrackRequest mute = 9;
     */
    value: MuteTrackRequest;
    case: "mute";
  } | {
    /**
     * indicates changes to speaker status, including when they've gone to not speaking
     *
     * @generated from field: livekit.SpeakersChanged speakers_changed = 10;
     */
    value: SpeakersChanged;
    case: "speakersChanged";
  } | {
    /**
     * sent when metadata of the room has changed
     *
     * @generated from field: livekit.RoomUpdate room_update = 11;
     */
    value: RoomUpdate;
    case: "roomUpdate";
  } | {
    /**
     * when connection quality changed
     *
     * @generated from field: livekit.ConnectionQualityUpdate connection_quality = 12;
     */
    value: ConnectionQualityUpdate;
    case: "connectionQuality";
  } | {
    /**
     * when streamed tracks state changed, used to notify when any of the streams were paused due to
     * congestion
     *
     * @generated from field: livekit.StreamStateUpdate stream_state_update = 13;
     */
    value: StreamStateUpdate;
    case: "streamStateUpdate";
  } | {
    /**
     * when max subscribe quality changed, used by dynamic broadcasting to disable unused layers
     *
     * @generated from field: livekit.SubscribedQualityUpdate subscribed_quality_update = 14;
     */
    value: SubscribedQualityUpdate;
    case: "subscribedQualityUpdate";
  } | {
    /**
     * when subscription permission changed
     *
     * @generated from field: livekit.SubscriptionPermissionUpdate subscription_permission_update = 15;
     */
    value: SubscriptionPermissionUpdate;
    case: "subscriptionPermissionUpdate";
  } | {
    /**
     * update the token the client was using, to prevent an active client from using an expired token
     *
     * @generated from field: string refresh_token = 16;
     */
    value: string;
    case: "refreshToken";
  } | {
    /**
     * server initiated track unpublish
     *
     * @generated from field: livekit.TrackUnpublishedResponse track_unpublished = 17;
     */
    value: TrackUnpublishedResponse;
    case: "trackUnpublished";
  } | {
    /**
     * respond to ping
     *
     * deprecated by pong_resp (message Pong)
     *
     * @generated from field: int64 pong = 18;
     */
    value: bigint;
    case: "pong";
  } | {
    /**
     * sent when client reconnects
     *
     * @generated from field: livekit.ReconnectResponse reconnect = 19;
     */
    value: ReconnectResponse;
    case: "reconnect";
  } | {
    /**
     * respond to Ping
     *
     * @generated from field: livekit.Pong pong_resp = 20;
     */
    value: Pong;
    case: "pongResp";
  } | {
    /**
     * Subscription response, client should not expect any media from this subscription if it fails
     *
     * @generated from field: livekit.SubscriptionResponse subscription_response = 21;
     */
    value: SubscriptionResponse;
    case: "subscriptionResponse";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SignalResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SignalResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "join", kind: "message", T: JoinResponse, oneof: "message" },
    { no: 2, name: "answer", kind: "message", T: SessionDescription, oneof: "message" },
    { no: 3, name: "offer", kind: "message", T: SessionDescription, oneof: "message" },
    { no: 4, name: "trickle", kind: "message", T: TrickleRequest, oneof: "message" },
    { no: 5, name: "update", kind: "message", T: ParticipantUpdate, oneof: "message" },
    { no: 6, name: "track_published", kind: "message", T: TrackPublishedResponse, oneof: "message" },
    { no: 8, name: "leave", kind: "message", T: LeaveRequest, oneof: "message" },
    { no: 9, name: "mute", kind: "message", T: MuteTrackRequest, oneof: "message" },
    { no: 10, name: "speakers_changed", kind: "message", T: SpeakersChanged, oneof: "message" },
    { no: 11, name: "room_update", kind: "message", T: RoomUpdate, oneof: "message" },
    { no: 12, name: "connection_quality", kind: "message", T: ConnectionQualityUpdate, oneof: "message" },
    { no: 13, name: "stream_state_update", kind: "message", T: StreamStateUpdate, oneof: "message" },
    { no: 14, name: "subscribed_quality_update", kind: "message", T: SubscribedQualityUpdate, oneof: "message" },
    { no: 15, name: "subscription_permission_update", kind: "message", T: SubscriptionPermissionUpdate, oneof: "message" },
    { no: 16, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "message" },
    { no: 17, name: "track_unpublished", kind: "message", T: TrackUnpublishedResponse, oneof: "message" },
    { no: 18, name: "pong", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "message" },
    { no: 19, name: "reconnect", kind: "message", T: ReconnectResponse, oneof: "message" },
    { no: 20, name: "pong_resp", kind: "message", T: Pong, oneof: "message" },
    { no: 21, name: "subscription_response", kind: "message", T: SubscriptionResponse, oneof: "message" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignalResponse {
    return new SignalResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignalResponse {
    return new SignalResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignalResponse {
    return new SignalResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SignalResponse | PlainMessage<SignalResponse> | undefined, b: SignalResponse | PlainMessage<SignalResponse> | undefined): boolean {
    return proto3.util.equals(SignalResponse, a, b);
  }
}

/**
 * @generated from message livekit.SimulcastCodec
 */
export class SimulcastCodec extends Message<SimulcastCodec> {
  /**
   * @generated from field: string codec = 1;
   */
  codec = "";

  /**
   * @generated from field: string cid = 2;
   */
  cid = "";

  /**
   * @generated from field: bool enable_simulcast_layers = 3;
   */
  enableSimulcastLayers = false;

  constructor(data?: PartialMessage<SimulcastCodec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SimulcastCodec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "codec", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enable_simulcast_layers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimulcastCodec {
    return new SimulcastCodec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimulcastCodec {
    return new SimulcastCodec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimulcastCodec {
    return new SimulcastCodec().fromJsonString(jsonString, options);
  }

  static equals(a: SimulcastCodec | PlainMessage<SimulcastCodec> | undefined, b: SimulcastCodec | PlainMessage<SimulcastCodec> | undefined): boolean {
    return proto3.util.equals(SimulcastCodec, a, b);
  }
}

/**
 * @generated from message livekit.AddTrackRequest
 */
export class AddTrackRequest extends Message<AddTrackRequest> {
  /**
   * client ID of track, to match it when RTC track is received
   *
   * @generated from field: string cid = 1;
   */
  cid = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: livekit.TrackType type = 3;
   */
  type = TrackType.AUDIO;

  /**
   * to be deprecated in favor of layers
   *
   * @generated from field: uint32 width = 4;
   */
  width = 0;

  /**
   * @generated from field: uint32 height = 5;
   */
  height = 0;

  /**
   * true to add track and initialize to muted
   *
   * @generated from field: bool muted = 6;
   */
  muted = false;

  /**
   * true if DTX (Discontinuous Transmission) is disabled for audio
   *
   * @generated from field: bool disable_dtx = 7;
   */
  disableDtx = false;

  /**
   * @generated from field: livekit.TrackSource source = 8;
   */
  source = TrackSource.UNKNOWN;

  /**
   * @generated from field: repeated livekit.VideoLayer layers = 9;
   */
  layers: VideoLayer[] = [];

  /**
   * @generated from field: repeated livekit.SimulcastCodec simulcast_codecs = 10;
   */
  simulcastCodecs: SimulcastCodec[] = [];

  /**
   * server ID of track, publish new codec to exist track
   *
   * @generated from field: string sid = 11;
   */
  sid = "";

  /**
   * @generated from field: bool stereo = 12;
   */
  stereo = false;

  /**
   * true if RED (Redundant Encoding) is disabled for audio
   *
   * @generated from field: bool disable_red = 13;
   */
  disableRed = false;

  /**
   * @generated from field: livekit.Encryption.Type encryption = 14;
   */
  encryption = Encryption_Type.NONE;

  /**
   * which stream the track belongs to, used to group tracks together.
   * if not specified, server will infer it from track source to bundle camera/microphone, screenshare/audio together
   *
   * @generated from field: string stream = 15;
   */
  stream = "";

  constructor(data?: PartialMessage<AddTrackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.AddTrackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(TrackType) },
    { no: 4, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "muted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "disable_dtx", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "source", kind: "enum", T: proto3.getEnumType(TrackSource) },
    { no: 9, name: "layers", kind: "message", T: VideoLayer, repeated: true },
    { no: 10, name: "simulcast_codecs", kind: "message", T: SimulcastCodec, repeated: true },
    { no: 11, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "stereo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "disable_red", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "encryption", kind: "enum", T: proto3.getEnumType(Encryption_Type) },
    { no: 15, name: "stream", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTrackRequest {
    return new AddTrackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTrackRequest {
    return new AddTrackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTrackRequest {
    return new AddTrackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddTrackRequest | PlainMessage<AddTrackRequest> | undefined, b: AddTrackRequest | PlainMessage<AddTrackRequest> | undefined): boolean {
    return proto3.util.equals(AddTrackRequest, a, b);
  }
}

/**
 * @generated from message livekit.TrickleRequest
 */
export class TrickleRequest extends Message<TrickleRequest> {
  /**
   * @generated from field: string candidateInit = 1;
   */
  candidateInit = "";

  /**
   * @generated from field: livekit.SignalTarget target = 2;
   */
  target = SignalTarget.PUBLISHER;

  constructor(data?: PartialMessage<TrickleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TrickleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "candidateInit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target", kind: "enum", T: proto3.getEnumType(SignalTarget) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrickleRequest {
    return new TrickleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrickleRequest {
    return new TrickleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrickleRequest {
    return new TrickleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TrickleRequest | PlainMessage<TrickleRequest> | undefined, b: TrickleRequest | PlainMessage<TrickleRequest> | undefined): boolean {
    return proto3.util.equals(TrickleRequest, a, b);
  }
}

/**
 * @generated from message livekit.MuteTrackRequest
 */
export class MuteTrackRequest extends Message<MuteTrackRequest> {
  /**
   * @generated from field: string sid = 1;
   */
  sid = "";

  /**
   * @generated from field: bool muted = 2;
   */
  muted = false;

  constructor(data?: PartialMessage<MuteTrackRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.MuteTrackRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "muted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MuteTrackRequest {
    return new MuteTrackRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MuteTrackRequest {
    return new MuteTrackRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MuteTrackRequest {
    return new MuteTrackRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MuteTrackRequest | PlainMessage<MuteTrackRequest> | undefined, b: MuteTrackRequest | PlainMessage<MuteTrackRequest> | undefined): boolean {
    return proto3.util.equals(MuteTrackRequest, a, b);
  }
}

/**
 * @generated from message livekit.JoinResponse
 */
export class JoinResponse extends Message<JoinResponse> {
  /**
   * @generated from field: livekit.Room room = 1;
   */
  room?: Room;

  /**
   * @generated from field: livekit.ParticipantInfo participant = 2;
   */
  participant?: ParticipantInfo;

  /**
   * @generated from field: repeated livekit.ParticipantInfo other_participants = 3;
   */
  otherParticipants: ParticipantInfo[] = [];

  /**
   * deprecated. use server_info.version instead.
   *
   * @generated from field: string server_version = 4;
   */
  serverVersion = "";

  /**
   * @generated from field: repeated livekit.ICEServer ice_servers = 5;
   */
  iceServers: ICEServer[] = [];

  /**
   * use subscriber as the primary PeerConnection
   *
   * @generated from field: bool subscriber_primary = 6;
   */
  subscriberPrimary = false;

  /**
   * when the current server isn't available, return alternate url to retry connection
   * when this is set, the other fields will be largely empty
   *
   * @generated from field: string alternative_url = 7;
   */
  alternativeUrl = "";

  /**
   * @generated from field: livekit.ClientConfiguration client_configuration = 8;
   */
  clientConfiguration?: ClientConfiguration;

  /**
   * deprecated. use server_info.region instead.
   *
   * @generated from field: string server_region = 9;
   */
  serverRegion = "";

  /**
   * @generated from field: int32 ping_timeout = 10;
   */
  pingTimeout = 0;

  /**
   * @generated from field: int32 ping_interval = 11;
   */
  pingInterval = 0;

  /**
   * @generated from field: livekit.ServerInfo server_info = 12;
   */
  serverInfo?: ServerInfo;

  /**
   * Server-Injected-Frame byte trailer, used to identify unencrypted frames when e2ee is enabled
   *
   * @generated from field: bytes sif_trailer = 13;
   */
  sifTrailer = new Uint8Array(0);

  constructor(data?: PartialMessage<JoinResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.JoinResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room", kind: "message", T: Room },
    { no: 2, name: "participant", kind: "message", T: ParticipantInfo },
    { no: 3, name: "other_participants", kind: "message", T: ParticipantInfo, repeated: true },
    { no: 4, name: "server_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ice_servers", kind: "message", T: ICEServer, repeated: true },
    { no: 6, name: "subscriber_primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "alternative_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "client_configuration", kind: "message", T: ClientConfiguration },
    { no: 9, name: "server_region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "ping_timeout", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "ping_interval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "server_info", kind: "message", T: ServerInfo },
    { no: 13, name: "sif_trailer", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): JoinResponse {
    return new JoinResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): JoinResponse {
    return new JoinResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): JoinResponse {
    return new JoinResponse().fromJsonString(jsonString, options);
  }

  static equals(a: JoinResponse | PlainMessage<JoinResponse> | undefined, b: JoinResponse | PlainMessage<JoinResponse> | undefined): boolean {
    return proto3.util.equals(JoinResponse, a, b);
  }
}

/**
 * @generated from message livekit.ReconnectResponse
 */
export class ReconnectResponse extends Message<ReconnectResponse> {
  /**
   * @generated from field: repeated livekit.ICEServer ice_servers = 1;
   */
  iceServers: ICEServer[] = [];

  /**
   * @generated from field: livekit.ClientConfiguration client_configuration = 2;
   */
  clientConfiguration?: ClientConfiguration;

  constructor(data?: PartialMessage<ReconnectResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ReconnectResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ice_servers", kind: "message", T: ICEServer, repeated: true },
    { no: 2, name: "client_configuration", kind: "message", T: ClientConfiguration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReconnectResponse {
    return new ReconnectResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReconnectResponse {
    return new ReconnectResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReconnectResponse {
    return new ReconnectResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReconnectResponse | PlainMessage<ReconnectResponse> | undefined, b: ReconnectResponse | PlainMessage<ReconnectResponse> | undefined): boolean {
    return proto3.util.equals(ReconnectResponse, a, b);
  }
}

/**
 * @generated from message livekit.TrackPublishedResponse
 */
export class TrackPublishedResponse extends Message<TrackPublishedResponse> {
  /**
   * @generated from field: string cid = 1;
   */
  cid = "";

  /**
   * @generated from field: livekit.TrackInfo track = 2;
   */
  track?: TrackInfo;

  constructor(data?: PartialMessage<TrackPublishedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TrackPublishedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "track", kind: "message", T: TrackInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackPublishedResponse {
    return new TrackPublishedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackPublishedResponse {
    return new TrackPublishedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackPublishedResponse {
    return new TrackPublishedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TrackPublishedResponse | PlainMessage<TrackPublishedResponse> | undefined, b: TrackPublishedResponse | PlainMessage<TrackPublishedResponse> | undefined): boolean {
    return proto3.util.equals(TrackPublishedResponse, a, b);
  }
}

/**
 * @generated from message livekit.TrackUnpublishedResponse
 */
export class TrackUnpublishedResponse extends Message<TrackUnpublishedResponse> {
  /**
   * @generated from field: string track_sid = 1;
   */
  trackSid = "";

  constructor(data?: PartialMessage<TrackUnpublishedResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TrackUnpublishedResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackUnpublishedResponse {
    return new TrackUnpublishedResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackUnpublishedResponse {
    return new TrackUnpublishedResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackUnpublishedResponse {
    return new TrackUnpublishedResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TrackUnpublishedResponse | PlainMessage<TrackUnpublishedResponse> | undefined, b: TrackUnpublishedResponse | PlainMessage<TrackUnpublishedResponse> | undefined): boolean {
    return proto3.util.equals(TrackUnpublishedResponse, a, b);
  }
}

/**
 * @generated from message livekit.SessionDescription
 */
export class SessionDescription extends Message<SessionDescription> {
  /**
   * "answer" | "offer" | "pranswer" | "rollback"
   *
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string sdp = 2;
   */
  sdp = "";

  constructor(data?: PartialMessage<SessionDescription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SessionDescription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sdp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SessionDescription {
    return new SessionDescription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SessionDescription {
    return new SessionDescription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SessionDescription {
    return new SessionDescription().fromJsonString(jsonString, options);
  }

  static equals(a: SessionDescription | PlainMessage<SessionDescription> | undefined, b: SessionDescription | PlainMessage<SessionDescription> | undefined): boolean {
    return proto3.util.equals(SessionDescription, a, b);
  }
}

/**
 * @generated from message livekit.ParticipantUpdate
 */
export class ParticipantUpdate extends Message<ParticipantUpdate> {
  /**
   * @generated from field: repeated livekit.ParticipantInfo participants = 1;
   */
  participants: ParticipantInfo[] = [];

  constructor(data?: PartialMessage<ParticipantUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ParticipantUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participants", kind: "message", T: ParticipantInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParticipantUpdate {
    return new ParticipantUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParticipantUpdate {
    return new ParticipantUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParticipantUpdate {
    return new ParticipantUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: ParticipantUpdate | PlainMessage<ParticipantUpdate> | undefined, b: ParticipantUpdate | PlainMessage<ParticipantUpdate> | undefined): boolean {
    return proto3.util.equals(ParticipantUpdate, a, b);
  }
}

/**
 * @generated from message livekit.UpdateSubscription
 */
export class UpdateSubscription extends Message<UpdateSubscription> {
  /**
   * @generated from field: repeated string track_sids = 1;
   */
  trackSids: string[] = [];

  /**
   * @generated from field: bool subscribe = 2;
   */
  subscribe = false;

  /**
   * @generated from field: repeated livekit.ParticipantTracks participant_tracks = 3;
   */
  participantTracks: ParticipantTracks[] = [];

  constructor(data?: PartialMessage<UpdateSubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.UpdateSubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "subscribe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "participant_tracks", kind: "message", T: ParticipantTracks, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSubscription {
    return new UpdateSubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSubscription {
    return new UpdateSubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSubscription {
    return new UpdateSubscription().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSubscription | PlainMessage<UpdateSubscription> | undefined, b: UpdateSubscription | PlainMessage<UpdateSubscription> | undefined): boolean {
    return proto3.util.equals(UpdateSubscription, a, b);
  }
}

/**
 * @generated from message livekit.UpdateTrackSettings
 */
export class UpdateTrackSettings extends Message<UpdateTrackSettings> {
  /**
   * @generated from field: repeated string track_sids = 1;
   */
  trackSids: string[] = [];

  /**
   * when true, the track is placed in a paused state, with no new data returned
   *
   * @generated from field: bool disabled = 3;
   */
  disabled = false;

  /**
   * deprecated in favor of width & height
   *
   * @generated from field: livekit.VideoQuality quality = 4;
   */
  quality = VideoQuality.LOW;

  /**
   * for video, width to receive
   *
   * @generated from field: uint32 width = 5;
   */
  width = 0;

  /**
   * for video, height to receive
   *
   * @generated from field: uint32 height = 6;
   */
  height = 0;

  /**
   * @generated from field: uint32 fps = 7;
   */
  fps = 0;

  /**
   * subscription priority. 1 being the highest (0 is unset)
   * when unset, server sill assign priority based on the order of subscription
   * server will use priority in the following ways:
   * 1. when subscribed tracks exceed per-participant subscription limit, server will
   *    pause the lowest priority tracks
   * 2. when the network is congested, server will assign available bandwidth to
   *    higher priority tracks first. lowest priority tracks can be paused
   *
   * @generated from field: uint32 priority = 8;
   */
  priority = 0;

  constructor(data?: PartialMessage<UpdateTrackSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.UpdateTrackSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 5, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "fps", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "priority", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTrackSettings {
    return new UpdateTrackSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTrackSettings {
    return new UpdateTrackSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTrackSettings {
    return new UpdateTrackSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTrackSettings | PlainMessage<UpdateTrackSettings> | undefined, b: UpdateTrackSettings | PlainMessage<UpdateTrackSettings> | undefined): boolean {
    return proto3.util.equals(UpdateTrackSettings, a, b);
  }
}

/**
 * @generated from message livekit.LeaveRequest
 */
export class LeaveRequest extends Message<LeaveRequest> {
  /**
   * sent when server initiates the disconnect due to server-restart
   * indicates clients should attempt full-reconnect sequence
   *
   * @generated from field: bool can_reconnect = 1;
   */
  canReconnect = false;

  /**
   * @generated from field: livekit.DisconnectReason reason = 2;
   */
  reason = DisconnectReason.UNKNOWN_REASON;

  constructor(data?: PartialMessage<LeaveRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.LeaveRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "can_reconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "enum", T: proto3.getEnumType(DisconnectReason) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeaveRequest {
    return new LeaveRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeaveRequest {
    return new LeaveRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeaveRequest {
    return new LeaveRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LeaveRequest | PlainMessage<LeaveRequest> | undefined, b: LeaveRequest | PlainMessage<LeaveRequest> | undefined): boolean {
    return proto3.util.equals(LeaveRequest, a, b);
  }
}

/**
 * message to indicate published video track dimensions are changing
 *
 * @generated from message livekit.UpdateVideoLayers
 */
export class UpdateVideoLayers extends Message<UpdateVideoLayers> {
  /**
   * @generated from field: string track_sid = 1;
   */
  trackSid = "";

  /**
   * @generated from field: repeated livekit.VideoLayer layers = 2;
   */
  layers: VideoLayer[] = [];

  constructor(data?: PartialMessage<UpdateVideoLayers>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.UpdateVideoLayers";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "layers", kind: "message", T: VideoLayer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateVideoLayers {
    return new UpdateVideoLayers().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateVideoLayers {
    return new UpdateVideoLayers().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateVideoLayers {
    return new UpdateVideoLayers().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateVideoLayers | PlainMessage<UpdateVideoLayers> | undefined, b: UpdateVideoLayers | PlainMessage<UpdateVideoLayers> | undefined): boolean {
    return proto3.util.equals(UpdateVideoLayers, a, b);
  }
}

/**
 * @generated from message livekit.UpdateParticipantMetadata
 */
export class UpdateParticipantMetadata extends Message<UpdateParticipantMetadata> {
  /**
   * @generated from field: string metadata = 1;
   */
  metadata = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<UpdateParticipantMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.UpdateParticipantMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateParticipantMetadata {
    return new UpdateParticipantMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateParticipantMetadata {
    return new UpdateParticipantMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateParticipantMetadata {
    return new UpdateParticipantMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateParticipantMetadata | PlainMessage<UpdateParticipantMetadata> | undefined, b: UpdateParticipantMetadata | PlainMessage<UpdateParticipantMetadata> | undefined): boolean {
    return proto3.util.equals(UpdateParticipantMetadata, a, b);
  }
}

/**
 * @generated from message livekit.ICEServer
 */
export class ICEServer extends Message<ICEServer> {
  /**
   * @generated from field: repeated string urls = 1;
   */
  urls: string[] = [];

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string credential = 3;
   */
  credential = "";

  constructor(data?: PartialMessage<ICEServer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ICEServer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "credential", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ICEServer {
    return new ICEServer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ICEServer {
    return new ICEServer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ICEServer {
    return new ICEServer().fromJsonString(jsonString, options);
  }

  static equals(a: ICEServer | PlainMessage<ICEServer> | undefined, b: ICEServer | PlainMessage<ICEServer> | undefined): boolean {
    return proto3.util.equals(ICEServer, a, b);
  }
}

/**
 * @generated from message livekit.SpeakersChanged
 */
export class SpeakersChanged extends Message<SpeakersChanged> {
  /**
   * @generated from field: repeated livekit.SpeakerInfo speakers = 1;
   */
  speakers: SpeakerInfo[] = [];

  constructor(data?: PartialMessage<SpeakersChanged>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SpeakersChanged";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "speakers", kind: "message", T: SpeakerInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpeakersChanged {
    return new SpeakersChanged().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpeakersChanged {
    return new SpeakersChanged().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpeakersChanged {
    return new SpeakersChanged().fromJsonString(jsonString, options);
  }

  static equals(a: SpeakersChanged | PlainMessage<SpeakersChanged> | undefined, b: SpeakersChanged | PlainMessage<SpeakersChanged> | undefined): boolean {
    return proto3.util.equals(SpeakersChanged, a, b);
  }
}

/**
 * @generated from message livekit.RoomUpdate
 */
export class RoomUpdate extends Message<RoomUpdate> {
  /**
   * @generated from field: livekit.Room room = 1;
   */
  room?: Room;

  constructor(data?: PartialMessage<RoomUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.RoomUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "room", kind: "message", T: Room },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoomUpdate {
    return new RoomUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoomUpdate {
    return new RoomUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoomUpdate {
    return new RoomUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: RoomUpdate | PlainMessage<RoomUpdate> | undefined, b: RoomUpdate | PlainMessage<RoomUpdate> | undefined): boolean {
    return proto3.util.equals(RoomUpdate, a, b);
  }
}

/**
 * @generated from message livekit.ConnectionQualityInfo
 */
export class ConnectionQualityInfo extends Message<ConnectionQualityInfo> {
  /**
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: livekit.ConnectionQuality quality = 2;
   */
  quality = ConnectionQuality.POOR;

  /**
   * @generated from field: float score = 3;
   */
  score = 0;

  constructor(data?: PartialMessage<ConnectionQualityInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ConnectionQualityInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quality", kind: "enum", T: proto3.getEnumType(ConnectionQuality) },
    { no: 3, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectionQualityInfo {
    return new ConnectionQualityInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectionQualityInfo {
    return new ConnectionQualityInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectionQualityInfo {
    return new ConnectionQualityInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectionQualityInfo | PlainMessage<ConnectionQualityInfo> | undefined, b: ConnectionQualityInfo | PlainMessage<ConnectionQualityInfo> | undefined): boolean {
    return proto3.util.equals(ConnectionQualityInfo, a, b);
  }
}

/**
 * @generated from message livekit.ConnectionQualityUpdate
 */
export class ConnectionQualityUpdate extends Message<ConnectionQualityUpdate> {
  /**
   * @generated from field: repeated livekit.ConnectionQualityInfo updates = 1;
   */
  updates: ConnectionQualityInfo[] = [];

  constructor(data?: PartialMessage<ConnectionQualityUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ConnectionQualityUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updates", kind: "message", T: ConnectionQualityInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectionQualityUpdate {
    return new ConnectionQualityUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectionQualityUpdate {
    return new ConnectionQualityUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectionQualityUpdate {
    return new ConnectionQualityUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectionQualityUpdate | PlainMessage<ConnectionQualityUpdate> | undefined, b: ConnectionQualityUpdate | PlainMessage<ConnectionQualityUpdate> | undefined): boolean {
    return proto3.util.equals(ConnectionQualityUpdate, a, b);
  }
}

/**
 * @generated from message livekit.StreamStateInfo
 */
export class StreamStateInfo extends Message<StreamStateInfo> {
  /**
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: string track_sid = 2;
   */
  trackSid = "";

  /**
   * @generated from field: livekit.StreamState state = 3;
   */
  state = StreamState.ACTIVE;

  constructor(data?: PartialMessage<StreamStateInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.StreamStateInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(StreamState) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamStateInfo {
    return new StreamStateInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamStateInfo {
    return new StreamStateInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamStateInfo {
    return new StreamStateInfo().fromJsonString(jsonString, options);
  }

  static equals(a: StreamStateInfo | PlainMessage<StreamStateInfo> | undefined, b: StreamStateInfo | PlainMessage<StreamStateInfo> | undefined): boolean {
    return proto3.util.equals(StreamStateInfo, a, b);
  }
}

/**
 * @generated from message livekit.StreamStateUpdate
 */
export class StreamStateUpdate extends Message<StreamStateUpdate> {
  /**
   * @generated from field: repeated livekit.StreamStateInfo stream_states = 1;
   */
  streamStates: StreamStateInfo[] = [];

  constructor(data?: PartialMessage<StreamStateUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.StreamStateUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stream_states", kind: "message", T: StreamStateInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StreamStateUpdate {
    return new StreamStateUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StreamStateUpdate {
    return new StreamStateUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StreamStateUpdate {
    return new StreamStateUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: StreamStateUpdate | PlainMessage<StreamStateUpdate> | undefined, b: StreamStateUpdate | PlainMessage<StreamStateUpdate> | undefined): boolean {
    return proto3.util.equals(StreamStateUpdate, a, b);
  }
}

/**
 * @generated from message livekit.SubscribedQuality
 */
export class SubscribedQuality extends Message<SubscribedQuality> {
  /**
   * @generated from field: livekit.VideoQuality quality = 1;
   */
  quality = VideoQuality.LOW;

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  constructor(data?: PartialMessage<SubscribedQuality>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscribedQuality";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribedQuality {
    return new SubscribedQuality().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribedQuality {
    return new SubscribedQuality().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribedQuality {
    return new SubscribedQuality().fromJsonString(jsonString, options);
  }

  static equals(a: SubscribedQuality | PlainMessage<SubscribedQuality> | undefined, b: SubscribedQuality | PlainMessage<SubscribedQuality> | undefined): boolean {
    return proto3.util.equals(SubscribedQuality, a, b);
  }
}

/**
 * @generated from message livekit.SubscribedCodec
 */
export class SubscribedCodec extends Message<SubscribedCodec> {
  /**
   * @generated from field: string codec = 1;
   */
  codec = "";

  /**
   * @generated from field: repeated livekit.SubscribedQuality qualities = 2;
   */
  qualities: SubscribedQuality[] = [];

  constructor(data?: PartialMessage<SubscribedCodec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscribedCodec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "codec", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "qualities", kind: "message", T: SubscribedQuality, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribedCodec {
    return new SubscribedCodec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribedCodec {
    return new SubscribedCodec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribedCodec {
    return new SubscribedCodec().fromJsonString(jsonString, options);
  }

  static equals(a: SubscribedCodec | PlainMessage<SubscribedCodec> | undefined, b: SubscribedCodec | PlainMessage<SubscribedCodec> | undefined): boolean {
    return proto3.util.equals(SubscribedCodec, a, b);
  }
}

/**
 * @generated from message livekit.SubscribedQualityUpdate
 */
export class SubscribedQualityUpdate extends Message<SubscribedQualityUpdate> {
  /**
   * @generated from field: string track_sid = 1;
   */
  trackSid = "";

  /**
   * @generated from field: repeated livekit.SubscribedQuality subscribed_qualities = 2;
   */
  subscribedQualities: SubscribedQuality[] = [];

  /**
   * @generated from field: repeated livekit.SubscribedCodec subscribed_codecs = 3;
   */
  subscribedCodecs: SubscribedCodec[] = [];

  constructor(data?: PartialMessage<SubscribedQualityUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscribedQualityUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subscribed_qualities", kind: "message", T: SubscribedQuality, repeated: true },
    { no: 3, name: "subscribed_codecs", kind: "message", T: SubscribedCodec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscribedQualityUpdate {
    return new SubscribedQualityUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscribedQualityUpdate {
    return new SubscribedQualityUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscribedQualityUpdate {
    return new SubscribedQualityUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: SubscribedQualityUpdate | PlainMessage<SubscribedQualityUpdate> | undefined, b: SubscribedQualityUpdate | PlainMessage<SubscribedQualityUpdate> | undefined): boolean {
    return proto3.util.equals(SubscribedQualityUpdate, a, b);
  }
}

/**
 * @generated from message livekit.TrackPermission
 */
export class TrackPermission extends Message<TrackPermission> {
  /**
   * permission could be granted either by participant sid or identity
   *
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: bool all_tracks = 2;
   */
  allTracks = false;

  /**
   * @generated from field: repeated string track_sids = 3;
   */
  trackSids: string[] = [];

  /**
   * @generated from field: string participant_identity = 4;
   */
  participantIdentity = "";

  constructor(data?: PartialMessage<TrackPermission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TrackPermission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "all_tracks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "track_sids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "participant_identity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackPermission {
    return new TrackPermission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackPermission {
    return new TrackPermission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackPermission {
    return new TrackPermission().fromJsonString(jsonString, options);
  }

  static equals(a: TrackPermission | PlainMessage<TrackPermission> | undefined, b: TrackPermission | PlainMessage<TrackPermission> | undefined): boolean {
    return proto3.util.equals(TrackPermission, a, b);
  }
}

/**
 * @generated from message livekit.SubscriptionPermission
 */
export class SubscriptionPermission extends Message<SubscriptionPermission> {
  /**
   * @generated from field: bool all_participants = 1;
   */
  allParticipants = false;

  /**
   * @generated from field: repeated livekit.TrackPermission track_permissions = 2;
   */
  trackPermissions: TrackPermission[] = [];

  constructor(data?: PartialMessage<SubscriptionPermission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscriptionPermission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "all_participants", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "track_permissions", kind: "message", T: TrackPermission, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscriptionPermission {
    return new SubscriptionPermission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscriptionPermission {
    return new SubscriptionPermission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscriptionPermission {
    return new SubscriptionPermission().fromJsonString(jsonString, options);
  }

  static equals(a: SubscriptionPermission | PlainMessage<SubscriptionPermission> | undefined, b: SubscriptionPermission | PlainMessage<SubscriptionPermission> | undefined): boolean {
    return proto3.util.equals(SubscriptionPermission, a, b);
  }
}

/**
 * @generated from message livekit.SubscriptionPermissionUpdate
 */
export class SubscriptionPermissionUpdate extends Message<SubscriptionPermissionUpdate> {
  /**
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: string track_sid = 2;
   */
  trackSid = "";

  /**
   * @generated from field: bool allowed = 3;
   */
  allowed = false;

  constructor(data?: PartialMessage<SubscriptionPermissionUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscriptionPermissionUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "allowed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscriptionPermissionUpdate {
    return new SubscriptionPermissionUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscriptionPermissionUpdate {
    return new SubscriptionPermissionUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscriptionPermissionUpdate {
    return new SubscriptionPermissionUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: SubscriptionPermissionUpdate | PlainMessage<SubscriptionPermissionUpdate> | undefined, b: SubscriptionPermissionUpdate | PlainMessage<SubscriptionPermissionUpdate> | undefined): boolean {
    return proto3.util.equals(SubscriptionPermissionUpdate, a, b);
  }
}

/**
 * @generated from message livekit.SyncState
 */
export class SyncState extends Message<SyncState> {
  /**
   * last subscribe answer before reconnecting
   *
   * @generated from field: livekit.SessionDescription answer = 1;
   */
  answer?: SessionDescription;

  /**
   * @generated from field: livekit.UpdateSubscription subscription = 2;
   */
  subscription?: UpdateSubscription;

  /**
   * @generated from field: repeated livekit.TrackPublishedResponse publish_tracks = 3;
   */
  publishTracks: TrackPublishedResponse[] = [];

  /**
   * @generated from field: repeated livekit.DataChannelInfo data_channels = 4;
   */
  dataChannels: DataChannelInfo[] = [];

  /**
   * last received server side offer before reconnecting
   *
   * @generated from field: livekit.SessionDescription offer = 5;
   */
  offer?: SessionDescription;

  constructor(data?: PartialMessage<SyncState>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SyncState";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "answer", kind: "message", T: SessionDescription },
    { no: 2, name: "subscription", kind: "message", T: UpdateSubscription },
    { no: 3, name: "publish_tracks", kind: "message", T: TrackPublishedResponse, repeated: true },
    { no: 4, name: "data_channels", kind: "message", T: DataChannelInfo, repeated: true },
    { no: 5, name: "offer", kind: "message", T: SessionDescription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncState {
    return new SyncState().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncState {
    return new SyncState().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncState {
    return new SyncState().fromJsonString(jsonString, options);
  }

  static equals(a: SyncState | PlainMessage<SyncState> | undefined, b: SyncState | PlainMessage<SyncState> | undefined): boolean {
    return proto3.util.equals(SyncState, a, b);
  }
}

/**
 * @generated from message livekit.DataChannelInfo
 */
export class DataChannelInfo extends Message<DataChannelInfo> {
  /**
   * @generated from field: string label = 1;
   */
  label = "";

  /**
   * @generated from field: uint32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: livekit.SignalTarget target = 3;
   */
  target = SignalTarget.PUBLISHER;

  constructor(data?: PartialMessage<DataChannelInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.DataChannelInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "target", kind: "enum", T: proto3.getEnumType(SignalTarget) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataChannelInfo {
    return new DataChannelInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataChannelInfo {
    return new DataChannelInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataChannelInfo {
    return new DataChannelInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DataChannelInfo | PlainMessage<DataChannelInfo> | undefined, b: DataChannelInfo | PlainMessage<DataChannelInfo> | undefined): boolean {
    return proto3.util.equals(DataChannelInfo, a, b);
  }
}

/**
 * @generated from message livekit.SimulateScenario
 */
export class SimulateScenario extends Message<SimulateScenario> {
  /**
   * @generated from oneof livekit.SimulateScenario.scenario
   */
  scenario: {
    /**
     * simulate N seconds of speaker activity
     *
     * @generated from field: int32 speaker_update = 1;
     */
    value: number;
    case: "speakerUpdate";
  } | {
    /**
     * simulate local node failure
     *
     * @generated from field: bool node_failure = 2;
     */
    value: boolean;
    case: "nodeFailure";
  } | {
    /**
     * simulate migration
     *
     * @generated from field: bool migration = 3;
     */
    value: boolean;
    case: "migration";
  } | {
    /**
     * server to send leave
     *
     * @generated from field: bool server_leave = 4;
     */
    value: boolean;
    case: "serverLeave";
  } | {
    /**
     * switch candidate protocol to tcp
     *
     * @generated from field: livekit.CandidateProtocol switch_candidate_protocol = 5;
     */
    value: CandidateProtocol;
    case: "switchCandidateProtocol";
  } | {
    /**
     * maximum bandwidth for subscribers, in bps
     * when zero, clears artificial bandwidth limit
     *
     * @generated from field: int64 subscriber_bandwidth = 6;
     */
    value: bigint;
    case: "subscriberBandwidth";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SimulateScenario>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SimulateScenario";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "speaker_update", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "scenario" },
    { no: 2, name: "node_failure", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "scenario" },
    { no: 3, name: "migration", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "scenario" },
    { no: 4, name: "server_leave", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "scenario" },
    { no: 5, name: "switch_candidate_protocol", kind: "enum", T: proto3.getEnumType(CandidateProtocol), oneof: "scenario" },
    { no: 6, name: "subscriber_bandwidth", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "scenario" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimulateScenario {
    return new SimulateScenario().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimulateScenario {
    return new SimulateScenario().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimulateScenario {
    return new SimulateScenario().fromJsonString(jsonString, options);
  }

  static equals(a: SimulateScenario | PlainMessage<SimulateScenario> | undefined, b: SimulateScenario | PlainMessage<SimulateScenario> | undefined): boolean {
    return proto3.util.equals(SimulateScenario, a, b);
  }
}

/**
 * @generated from message livekit.Ping
 */
export class Ping extends Message<Ping> {
  /**
   * @generated from field: int64 timestamp = 1;
   */
  timestamp = protoInt64.zero;

  /**
   * rtt in milliseconds calculated by client
   *
   * @generated from field: int64 rtt = 2;
   */
  rtt = protoInt64.zero;

  constructor(data?: PartialMessage<Ping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.Ping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "rtt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Ping {
    return new Ping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Ping {
    return new Ping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Ping {
    return new Ping().fromJsonString(jsonString, options);
  }

  static equals(a: Ping | PlainMessage<Ping> | undefined, b: Ping | PlainMessage<Ping> | undefined): boolean {
    return proto3.util.equals(Ping, a, b);
  }
}

/**
 * @generated from message livekit.Pong
 */
export class Pong extends Message<Pong> {
  /**
   * timestamp field of last received ping request
   *
   * @generated from field: int64 last_ping_timestamp = 1;
   */
  lastPingTimestamp = protoInt64.zero;

  /**
   * @generated from field: int64 timestamp = 2;
   */
  timestamp = protoInt64.zero;

  constructor(data?: PartialMessage<Pong>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.Pong";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "last_ping_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pong {
    return new Pong().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pong {
    return new Pong().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pong {
    return new Pong().fromJsonString(jsonString, options);
  }

  static equals(a: Pong | PlainMessage<Pong> | undefined, b: Pong | PlainMessage<Pong> | undefined): boolean {
    return proto3.util.equals(Pong, a, b);
  }
}

/**
 * @generated from message livekit.RegionSettings
 */
export class RegionSettings extends Message<RegionSettings> {
  /**
   * @generated from field: repeated livekit.RegionInfo regions = 1;
   */
  regions: RegionInfo[] = [];

  constructor(data?: PartialMessage<RegionSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.RegionSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "regions", kind: "message", T: RegionInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegionSettings {
    return new RegionSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegionSettings {
    return new RegionSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegionSettings {
    return new RegionSettings().fromJsonString(jsonString, options);
  }

  static equals(a: RegionSettings | PlainMessage<RegionSettings> | undefined, b: RegionSettings | PlainMessage<RegionSettings> | undefined): boolean {
    return proto3.util.equals(RegionSettings, a, b);
  }
}

/**
 * @generated from message livekit.RegionInfo
 */
export class RegionInfo extends Message<RegionInfo> {
  /**
   * @generated from field: string region = 1;
   */
  region = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: int64 distance = 3;
   */
  distance = protoInt64.zero;

  constructor(data?: PartialMessage<RegionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.RegionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "distance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegionInfo {
    return new RegionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegionInfo {
    return new RegionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegionInfo {
    return new RegionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RegionInfo | PlainMessage<RegionInfo> | undefined, b: RegionInfo | PlainMessage<RegionInfo> | undefined): boolean {
    return proto3.util.equals(RegionInfo, a, b);
  }
}

/**
 * @generated from message livekit.SubscriptionResponse
 */
export class SubscriptionResponse extends Message<SubscriptionResponse> {
  /**
   * @generated from field: string track_sid = 1;
   */
  trackSid = "";

  /**
   * @generated from field: livekit.SubscriptionError err = 2;
   */
  err = SubscriptionError.SE_UNKNOWN;

  constructor(data?: PartialMessage<SubscriptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SubscriptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "track_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "err", kind: "enum", T: proto3.getEnumType(SubscriptionError) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscriptionResponse {
    return new SubscriptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscriptionResponse {
    return new SubscriptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscriptionResponse {
    return new SubscriptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SubscriptionResponse | PlainMessage<SubscriptionResponse> | undefined, b: SubscriptionResponse | PlainMessage<SubscriptionResponse> | undefined): boolean {
    return proto3.util.equals(SubscriptionResponse, a, b);
  }
}

